/* eslint-disable no-unused-vars */
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import productCategoryService from '@/http/requests/product/productCategoryService'
import Pager from '@core/dev-extreme/utils/pager'

const isNotEmpty = value => value !== undefined && value !== null && value !== ''

const updatedObject = {}

const store = new CustomStore({
  key: 'id',
  load: ({ additionalParams, ...loadOptions }) => {
    const options = {}
    const pager = new Pager(options)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)

    const pagebleString = pager.toCreatePageable()
    const params = `?${pagebleString}`

    return productCategoryService
      .fetchAll(params)
      .then(response => {
        const data = response.data
        return {
          data: data.content,
          totalCount: data.totalElements,
        }
      })
  },
  insert: values => {
    productCategoryService.create(values)
  },
  update: (key, values) => {
    const data = {
      id: key,
      ...values,
    }
    productCategoryService.update(data)
  },
  remove: key => {
    productCategoryService.deleteById(key)
  },
})

const dataSource = new DataSource({
  store: store,
})

export {
  dataSource,
  updatedObject,
}
