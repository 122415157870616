<template>
  <div class="container-fluid">
    <dx-data-grid
      id="productCategoryGrid"
      ref="productCategoryGridRef"
      :data-source="dataSource"
      :height="setHeight"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :show-borders="true"
      :hover-state-enabled="true"
      :row-alternation-enabled="false"
      :remote-operations="true"
      @initialized="onInitialized"
      @content-ready="onContentReady"
      @row-updating="updateRow"
    >
      <dx-sorting mode="single" />
      <dx-column-chooser :enabled="true" mode="select" title="Column Chooser" />
      <dx-search-panel :visible="true" :width="200" placeholder="Search..." />
      <dx-filter-row :visible="true" />
      <dx-column data-field="name">
        <dx-util-required-rule />
      </dx-column>
      <dx-column data-field="conditionsAllowed" />
      <dx-column data-field="isApprovalRequired" width="240" />
      <dx-column data-field="description" :allow-filtering="false" />
      <dx-editing
        refresh-mode="full"
        :allow-adding="true"
        :allow-updating="true"
        :allow-deleting="true"
        :use-icons="true"
        mode="popup"
      >
        <dx-popup
          :show-title="true"
          :width="720"
          :height="540"
          title="Product Category Info"
        />
        <dx-form
          :col-count="1"
          :show-colon-after-label="true"
          label-location="top"
        >
          <dx-util-simple-item data-field="name" />
          <dx-util-simple-item data-field="conditionsAllowed" />
          <dx-util-simple-item
            data-field="isApprovalRequired"
            editor-type="dxSelectBox"
            :editor-options="selectBoxOptions"
          />
          <dx-util-simple-item
            :editor-options="{ height: 100 }"
            data-field="description"
            editor-type="dxTextArea"
          />
        </dx-form>
      </dx-editing>
      <dx-column
        type="buttons"
        caption="Actions"
      >
        <dx-button name="edit" />
        <dx-button name="delete" />
      </dx-column>
      <dx-paging :page-size="pagerOptions.pageSize" />
      <dx-pager
        :allowed-page-sizes="pagerOptions.allowedPageSizes"
        :show-info="pagerOptions.showInfo"
        :show-navigation-buttons="pagerOptions.showNavigationButtons"
        :show-page-size-selector="pagerOptions.showPageSizeSelector"
        :visible="pagerOptions.visible"
      />
    </dx-data-grid>
  </div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import { dataSource, updatedObject } from './productCategoryStore'

export default {
  mixins: [GridBase],
  data() {
    return {
      dataSource,
      updatedObject,
      selectBoxOptions: {
        items: ['Yes', 'No'],
        value: 'No',
      },
    }
  },
  mounted() {
  },
  methods: {
    updateRow(e) {
      e.newData = Object.assign(e.oldData, e.newData)
    },
  },
}
</script>

<style></style>
